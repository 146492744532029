import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const DETECTION_OPTIONS = {
    // checks localstorage for cached language if not present uses the navigator.language || navigator.userLanguage to get it from browser
    order:               ["path", "localStorage", "navigator"],
    lookupFromPathIndex: 0,
    checkWhitelist:      true,
};

export const SUPPORTED_LANGUAGES = ["en", "zh", "es", "el", "de", "pt-BR", "pt-PT", "pi", "ru"];

export const init_i18n = () => {
    i18n
        // import & load translations from -> /public/locales
        .use(Backend)
        // https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // see opts @ https://www.i18next.com/overview/configuration-options
        .init({
            detection:     DETECTION_OPTIONS,
            whitelist:     SUPPORTED_LANGUAGES,
            fallbackLng:   "en",
            debug:         process.env.NODE_ENV === "development",
            interpolation: {
                escapeValue: false,
            },
            // backend: {
            //     loadPath: "locales/{{lng}}/{{ns}}.json",
            // },
            react: {
                useSuspense: false, // fixes 'no fallback UI was specified' in react i18next when using hooks
            },
            appendNamespaceToCIMode: true,
            load:                    "currentOnly",
        });
};

// i18n.on("languageChanged", function(lng) {
//     if (lng === i18n.options.fallbackLng[0]) {
//         if (window.location.pathname.includes("/" + i18n.options.fallbackLng[0])) {
//             const newUrl = window.location.pathname.replace("/" + i18n.options.fallbackLng[0], "");
//             window.location.replace(newUrl);
//         }
//     }
// });

const genRouteLocaleSlug = (languages, lowerCase = false) => {
    let url = "/:locale(";
    languages.forEach((lng, index) => {
        if (lowerCase) {
            lng = lng.toLowerCase();
        }
        if (languages[index + 1]) {
            url = url + lng + "|";
        } else {
            url = url + lng + ")?";
        }
    });
    return url;
};

export const baseRouteUrl = genRouteLocaleSlug(SUPPORTED_LANGUAGES);

export const caseInsensitiveCheck = (array, value) => {
    const index = array.findIndex((element) => {
        return element.toLowerCase() === value.toLowerCase();
    });
    return index;
};

export const urlLangParser = (currentPathname) => {
    const possible_lang = currentPathname.split("/")[1];
    if (SUPPORTED_LANGUAGES.includes(possible_lang)) {
        return currentPathname.replace("/" + possible_lang, "") || "/";
    }
    const index = caseInsensitiveCheck(SUPPORTED_LANGUAGES, possible_lang);
    if (index !== -1) {
        return currentPathname.replace("/" + possible_lang, "") || "/";
    }
    return currentPathname;
};


export const createLangUrl = (lng) => {
    const base = urlLangParser(window.location.pathname);
    if (lng === "en") {
        return base;
    }
    return "/" + lng + base;
};
