import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import Spinner from "./components/Utils/Spinner";
import { BrowserRouter } from "react-router-dom";
import { init_i18n } from "./i18n";

const rootElement = document.getElementById("root");
const App = lazy(() => import("./App"));

ReactDOM.render(
    <BrowserRouter>
        <Suspense fallback={<Spinner />}>
            <App />
        </Suspense>
    </BrowserRouter>,
    rootElement,
);

init_i18n();
